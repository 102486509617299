<template>
  <el-container id="app">
    <el-header class="centered">
      <el-row style="padding-top: 12px">
        <el-col :span="12">
          <el-menu :router="true" :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
            <el-menu-item index="1" :route="{ name: 'home'}">open source circular fashion</el-menu-item>
            <!-- <el-menu-item index="1">Rece</el-menu-item> -->
            <el-submenu index="2">
              <template slot="title">Browse</template>
              <el-menu-item index="2-0" :route="{name: 'catalogue', params: {category_id: 'all'}}">All</el-menu-item>
              <el-menu-item v-for="(cat,index) in categories" :index="'2-'+(index+1)" :key="cat['.key']" :route="{name: 'catalogue', params: {category_id:  cat['.key']}}">{{cat.label}}</el-menu-item>
            </el-submenu>
          </el-menu>
        </el-col>
        <el-col :span="12" style="padding-top: 12px; text-align: right">
          <AccountPanel></AccountPanel>
          <el-button type="danger" icon="upload" @click="$router.push({name: 'upload-project'})">Upload</el-button>
        </el-col>
      </el-row>
    </el-header>
    <el-main>
      <router-view></router-view>
    </el-main>
    <el-footer class="centered">
      <hr> (c) 2017 Fabricademy: A new textile academy -
      <router-link :to="{ name: 'home'}">Home</router-link> |
      <router-link :to="{ name: 'catalogue', params: { category_id: 'all'}}">Browse</router-link> |
      <router-link :to="{ name: 'upload-project'}" v-if="currentUser!=null">Upload</router-link>
      <router-link :to="{ name: 'login'}" v-else>Upload</router-link>
      <br>
    </el-footer>
  </el-container>
</template>
<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import AccountPanel from './components/AccountPanel.vue';

export default {
  name: 'app',
  data() {
    return {
      activeIndex: '0',
      searchText: '',
      categories: [],
      currentUser: firebase.auth().currentUser
    }
  },
  created() {
    const _this = this
    firebase.auth().onAuthStateChanged(function(user) {
      _this.currentUser = user
    })
  },
  mounted() {
    const db = firebase.database().ref()
    this.$bindAsArray('categories', db.child('library/categories').orderByChild('name'), null, function() {
      console.log('categories loaded')
    })

  },
  methods: {
    handleSelect() {

    }
  },
  components: {
    AccountPanel
  }
}

</script>
<style scoped>
body {
    /* background-image: url(./assets/patterns/subtle-pattern-6.jpg); */
      font-family: 'Raleway', sans-serif;
}
.content {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
}
#app {
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: 'Raleway', sans-serif;
  /* background: lavender; */
}
.el-header{
  width: 100%;
  margin: 0px;
padding:0px;
}
.el-menu-demo {
  border: none;
  padding-top: 0px;
}

.centered {
  vertical-align: top;
  height: 300px;
  padding-left: 10%;
  padding-right: 10%;
  text-align:center;
}
.content {
    margin-top: 48px;
    width: 80%;
    min-height: 600px;
    margin-left: auto;
    margin-right: auto;
}

.el-menu li.el-menu-item {
  border: none
}
.el-submenu li.el-menu-item {
  /* height: 24px; */
  text-align: left;

}

</style>

