<template  lang="html">
  <div class="profile">
    <div v-if="user!=null" >
        <router-link :to="{name: 'profile'}"><img :src="photo" class="avatar"/></router-link>
        <router-link :to="{name: 'profile'}">{{name}}</router-link>
        <el-button class="logout" @click="logout()">Logout</el-button>
    </div>
    <el-button v-else @click="login()">Login</el-button>
    <!-- <div v-else id="firebaseui-auth-container"></div> -->
  </div>
</template>
<script>
import firebase from 'firebase/app';
import 'firebase/auth';

export default {
  name: 'AccountPanel',
  data() {
    return {
      user: firebase.auth().currentUser,
      name: '',
      email: '',
      photo: '',
      userId: ''
    }
  },

  created() {
    var _this = this;
    // var uiConfig = {
    //   // signInSuccessUrl: '/success',
    //   callbacks: {
    //     signInSuccess: function(currentUser, credential, redirectUrl) {
    //       _this.loadUser()
    //       return true;
    //     },
    //   },
    //   signInFlow: 'popup',
    //   signInOptions: [
    //     firebase.auth.GoogleAuthProvider.PROVIDER_ID
    //     // # firebase.auth.EmailAuthProvider.PROVIDER_ID
    //   ]
    // };
    // var ui = new firebaseui.auth.AuthUI(firebase.auth());
    // ui.start('#firebaseui-auth-container', uiConfig);

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        _this.loadUser()
      } else {
        _this.user = null
      }
    });
  },
  methods: {
    loadUser() {
      this.user = firebase.auth().currentUser;
      if (this.user) {
        this.name = this.user.displayName;
        this.email = this.user.email;
        this.photo = this.user.photoURL;
        this.userId = this.user.uid;
      }
    },
    logout() {
      firebase.auth().signOut();
      this.user = null
      this.$router.push('/')
    },
    login() {
      this.$router.push({ 'name': 'login' })
    }
  }
}
</script>
<style scoped>
.avatar {
  width: 48px;
  height: 48px;
  border-radius: 48px;
  display: inline;
  vertical-align: middle;
}

.profile {
  float: right;
  margin-left: 24px;
}

.logout {
  margin-left: 12px;
}
</style>
