<template>
    <div class="content">
        <div class="hero">
            <div class="logo">
                <img src="../assets/logo-fabricademy.png" />
            </div>
            <h1>Welcome to the Fabricademy Open Source Circular fashion catalogue</h1>
            <p>Browse and share your designs and help grow this library!</p>
        </div>

        <Feature label="Recent uploads" :featured="latestRev" :loading="latest_loading" v-on:browseAll="browseAllRecent" />

        <div class="referral">
            <h3>What is Fabricademy?</h3>
            <p>Fabricademy is a transdisciplinary course that focuses on the development of new technologies applied in the textile industry, in its broad range of applications, from the fashion industry and the upcoming wearable market.</p>
            <a href="https://textile-academy.org/" target="_blank">
                <el-button type="info">Check out our website</el-button>
            </a>
        </div>

    </div>
</template>
<script>
import firebase from 'firebase/app';
import 'firebase/database';
import Feature from './Feature.vue'

let db, featuredRef, downloadedRef, latestRef

export default {

    components: {
        Feature
    },

    created() {
        const _this = this;
        db = firebase.database();
        // featuredRef = db.ref('library').child('featured').orderByChild('created').limitToFirst(4)
        // downloadedRef = db.ref('library').child('downloaded').orderByChild('created').limitToFirst(4)
        latestRef = db.ref('library').child('projects').orderByKey().limitToLast(8)

        // this.$bindAsArray('featured', featuredRef, null, function() {
        //     _this.feature_loading = false;
        // })
        // this.$bindAsArray('recent', downloadedRef, null, function() {
        //     _this.recent_loading = false;
        // })
        this.$bindAsArray('latest', latestRef, null, function() {
            // console.log(this.latest.map(item => item.created));
            _this.latest_loading = false;
        })


    },

    methods: {
        browseAllRecent() {
            this.$router.push('/catalogue/all')
        }
    },
    computed: {
        latestRev: function() {
            return this.latest.slice(0).reverse()
        }
    },

    data() {
        return {
            // 'feature_loading': true,
            // 'recent_loading': true,
            'latest_loading': true,
        }
    }
}
</script>
<style scoped>
.hero,
.referral {
    /* width: 80%;
    margin-left: auto;
    margin-right: auto; */
    font-size: 130%;
    text-align: center;
}

.searchbox {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}

.featured,
.recent {
    /* border: 1px solid #dedede; */
    padding: 12px;
    min-height: 350px;
    margin-bottom: 48px;
}

.centered {
    padding-top: 12px;
    text-align: center;
}

.muted {

    color: #cdcdcd;
}


.logo img {
    height: 180px;
}

.content {
    margin-top: 0px;
}
.hero > p {
    margin-bottom: 0;
}
</style>
