<template>
    <div class="pic-wrapper">
        <div class="pic" v-on:mouseover="mouseOver" v-on:mouseenter="mouseEnter" v-on:mouseleave="mouseExit">
            <div v-show="active" class="caption">
                {{caption}}<br><br>
                <el-button size="small" @click="$emit('click')">More</el-button>
            </div>
            <div>
                <img :src="url" />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "image-with-over",
    props: [
        'caption',
        'url'
    ],
    data() {
        return {
            active: false
        }
    },
    methods: {
        mouseOver() {
            this.active = true;
        },
        mouseEnter() {
            this.active = true;
        },
        mouseExit() {
            this.active = false;
        }
    }
}
</script>
<style>
.pic-wrapper {
    padding: 12px;
    padding-left: 0px;
}

.pic {
    width: 100%;
    position: relative;
    text-align: left;
}

.pic img {
    width: 100%;
}

.caption {
    z-index: 3;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    color: white;
    font-size: 1.3em;
    left: 0px;
    width: 100%;
    top: 0;
    bottom: 0;
    text-align: center;
    padding-top: 40%;
}
</style>
