import VueRouter from 'vue-router'
import * as firebase from 'firebase/app'
import Home from './components/Home.vue'
import NotFound from './components/NotFound.vue'

const Auth = () => import(/* webpackChunkName: "auth" */ './components/Auth.vue')
const Catalogue = () => import(/* webpackChunkName: "catalogue" */ './components/Catalogue.vue')
const ProjectDetail = () => import(/* webpackChunkName: "projectdetail" */ './components/ProjectDetail.vue')
const Upload = () => import(/* webpackChunkName: "upload" */ './components/UploadProject.vue')
const UserProfile = () => import(/* webpackChunkName: "userprofile" */ './components/UserProfile.vue')
const SearchResults = () => import(/* webpackChunkName: "searchresults" */ './components/SearchResults.vue')
const EditProject = () => import(/* webpackChunkName: "editproject" */ './components/EditProject.vue')

const router = new VueRouter({
    mode: 'history',
    routes: [
        { path: '/', component: Home, name: 'home' },
        { path: '/not-found', component: NotFound, name: 'not-found' },
        { path: '/login', component: Auth, name: 'login' },
        { path: '/profile', component: UserProfile, name: 'profile' },
        { path: '/success', component: Home, name: 'success' },
        // { path: '/catalogue', component: Catalogue, name: 'catalogue' },
        { path: '/catalogue/:category_id', component: Catalogue, name: 'catalogue' },
        { path: '/search', component: SearchResults, name: 'search' },
        { path: '/project/upload', component: Upload, name: 'upload-project' },
        { path: '/project/:id', component: ProjectDetail, name: 'project-detail' },
        { path: '/project/:id/edit', component: EditProject, name: 'project-edit' },
        { path: '*', component: NotFound },
    ]
})

router.beforeEach((to, from, next) => {
    // if (to.name === 'profile' || to.name === 'upload-project') {
    //     console.log('requires auth')
    //     if (firebase.auth().currentUser) {
    //         console.log('authenticated')
    //         return next()
    //     } else {
    //         console.log('not authenticated')
    //         return next({ name: 'login' })
    //     }
    // } else

    if (to.name === 'login' && firebase.auth().currentUser) {
        next({ name: 'home' })
    } else {
        return next()
    }
})
export default router
