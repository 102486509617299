<template>
    <div class="featured">
        <h3 class="muted">{{featureLabel}}</h3>
        <el-row v-loading="loading">
            <el-col :span="6" v-for="project in featured" :key="project['.key']">
                <router-link :to="{ name: 'project-detail', params: { id: project['.key']}}">
                    <image-with-over :caption="project.name" :url="project.thumbnail"></image-with-over>
                </router-link>
            </el-col>
        </el-row>
        <div class="centered" v-show="!loading && featured.length == 0" style="padding-top: 75px">
            No projects available
        </div>
        <div class="centered">
            <el-button type="secondary" @click="browseAll" v-show="featured && featured.length > 0">Browse all designs</el-button>
        </div>
    </div>
</template>
<script>
import ImageWithOver from './ImageWithOver.vue'

export default {

    components: {
        ImageWithOver
    },
    props: [
        'featured',
        'label',
        'loading'
    ],
    data() {
        return {

        }
    },
    computed: {
        featureLabel: function() {
            return this.label || 'No title'
        }
    },
    methods: {
        browseAll() {
            this.$emit('browseAll', this.featured)
        }
    }
}
</script>

<style scoped>
.featured {
    /* border: 1px solid #dedede; */
    padding: 12px;
    min-height: 350px;
    margin-bottom: 48px;
}

.centered {
    padding-top: 12px;
    text-align: center;
}

.muted {

    color: #cdcdcd;
}
</style>
