import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import VueFire from 'vuefire'
import firebase from 'firebase/app';
import 'firebase/auth';

import 'firebaseui/dist/firebaseui.css'

import { config } from './helpers/firebaseConfig'
import router from './router'

import {
    Dialog,
    Menu,
    Submenu,
    MenuItem,
    InfiniteScroll,
    Input,
    // Checkbox,
    // CheckboxButton,
    // CheckboxGroup,
    Select,
    Option,
    Button,
    Form,
    FormItem,
    Tag,
    Alert,
    Icon,
    Row,
    Col,
    Upload,
    Card,
    Carousel,
    CarouselItem,
    Container,
    Header,
    Main,
    Footer,
    Loading,
    MessageBox,
    Message,
    Notification
} from 'element-ui'

Vue.use(Dialog)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(InfiniteScroll)
Vue.use(Input)
Vue.use(Select)
Vue.use(Option)
Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Tag)
Vue.use(Alert)
Vue.use(Icon)
Vue.use(Row)
Vue.use(Col)
Vue.use(Upload)
Vue.use(Card)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Container)
Vue.use(Header)
Vue.use(Main)
Vue.use(Footer)

Vue.use(Loading.directive)

Vue.prototype.$loading = Loading.service
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$notify = Notification
Vue.prototype.$message = Message

firebase.initializeApp(config);

Vue.use(VueFire)
Vue.use(VueRouter)

new Vue({
    router,
    created() {
        firebase.auth().onAuthStateChanged((user) => {
            // if (user) {
            //     this.$router.push('/')
            // } else {
            //     this.$router.push('/')
            // }
        });
    },
    el: '#app',
    render: h => h(App)
});
